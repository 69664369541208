<template>
  <v-menu v-model="computedValue" v-bind="{ ...menuStaticBind, ...menuBind }" :content-class="menuContentClass">
    <template #activator="activatorData" v-if="!hideActivator">
      <slot name="activator" v-bind="activatorData">
        <template v-if="type === 'tool'">
          <v-tooltip bottom color="font" :disabled="!buttonBind.tooltip">
            <template #activator="{ on }">
              <span v-on="on">
                <v-btn
                  v-bind="{ ...buttonBind, loading }"
                  v-on="{ ...activatorData.on, ...$listeners }"
                  :class="{ 'background-icon': computedValue }"
                  color="rgba(0, 0, 0, 0.7)"
                  icon
                  small
                >
                  <v-icon :color="$_colors.iconIdle" :class="{ 'active-icon': computedValue }">
                    {{ buttonBind.icon }}
                  </v-icon>
                </v-btn>
              </span>
            </template>
            {{ buttonBind.tooltip }}
          </v-tooltip>
        </template>
        <template v-else-if="type === 'menu'">
          <the-navbar-top-panel-menu-button
            v-bind="{ ...buttonBind }"
            v-on="{ ...activatorData.on, ...$listeners }"
            :class="{ 'background-icon': computedValue }"
          />
        </template>
      </slot>
    </template>
    <v-card :loading="loading" :class="contentClass" :style="hasFooter ? 'padding-bottom: 50px' : ''">
      <template v-if="hasCloseHeader && position === 'top'">
        <v-row no-gutters class="pr-1">
          <v-spacer />
          <v-col cols="auto">
            <v-btn x-small icon @click="computedValue = false">
              <v-icon size="18">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="hasCloseHeader && position === 'left'">
        <div style="position: sticky; top: 0; background-color: #fff; z-index: 2">
          <div class="py-4 px-2 d-flex justify-space-between">
            <span class="d-flex align-center" style="gap: 0.5rem">
              <span>{{ buttonBind.verboseName }}</span>
              <slot name="content.header.append" />
            </span>
            <v-icon @click="computedValue = false">mdi-close</v-icon>
          </div>
          <v-divider></v-divider>
        </div>
      </template>
      <slot name="content" />
    </v-card>
    <template v-if="hasFooter && position === 'left'">
      <div class="navbar-menu-tool-sticky-buttons d-flex align-center">
        <slot name="content.footer" />
      </div>
    </template>
  </v-menu>
</template>
<script>
import { get } from 'vuex-pathify';
import TheNavbarTopPanelMenuButton from '@/components/TheNavbarTopPanelMenuButton';
export default {
  name: 'NavbarMenuTool',
  components: { TheNavbarTopPanelMenuButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    menuData: {
      type: Object,
      required: true,
    },
    buttonBind: {
      type: Object,
      default: () => {
        return {};
      },
    },
    menuBind: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'tool',
      validator: v => ['tool', 'menu'].includes(v),
    },
    hasCloseHeader: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
    unfillWidth: {
      type: Boolean,
      default: false,
    },
    hideActivator: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    activeMenus: get('navbarMenus/activeMenus'),
    contentClass() {
      return `navbar-menu-tool-content-${this.position}`;
    },
    menuContentClass() {
      return `navbar-menu-tool-${this.position} ${this.unfillWidth ? '' : `navbar-menu-tool-${this.position}-fill-width`}`;
    },
    menuStaticBind() {
      return {
        closeOnClick: false,
        closeOnContentClick: false,
        attach: '.map',
        offsetY: true,
      };
    },
    position() {
      return this.menuData.position || 'top';
    },
    isCurrentMenuActive() {
      return this.activeMenus.find(item => item.name === this.menuData.name);
    },
  },
  mounted() {
    this.$watch(
      vm => {
        return { isCurrentMenuActive: vm.isCurrentMenuActive, isMenuOpen: vm.computedValue };
      },
      (nV, oV) => {
        const { isCurrentMenuActive: nIsCurrentMenuActive, isMenuOpen: nIsMenuOpen } = nV;
        const { isCurrentMenuActive: oIsCurrentMenuActive, isMenuOpen: oIsMenuOpen } = oV;
        if (oIsMenuOpen !== nIsMenuOpen)
          this.$store.commit('navbarMenus/SET_ACTIVE_TOOL', { data: this.menuData, value: nIsMenuOpen });
        if (oIsCurrentMenuActive && !nIsCurrentMenuActive) this.computedValue = false;
      }
    );
  },
};
</script>
<style lang="scss" scoped>
$panel-edge-margin: 16px;

.background-icon {
  @include activeToolButtonStyle();
  ::v-deep {
    .v-subheader {
      color: map-get($colors, 'primary') !important;
    }
  }
}
::v-deep {
  .active-icon * {
    color: map-get($colors, 'link') !important;
  }
}
.navbar-menu-tool-top {
  top: 0 !important;
  left: $panel-edge-margin !important;
}
body:has(.sider-menu__expanded) .navbar-menu-tool-top-fill-width {
  min-width: calc(
    100dvw - #{$sidebar-width__expanded} - #{$sider-menu__expanded} - (#{$panel-edge-margin} * 2)
  ) !important;
  width: calc(100dvw - #{$sidebar-width__expanded} - #{$sider-menu__expanded} - (#{$panel-edge-margin} * 2)) !important;
}
body:has(.sider-menu__collapsed) .navbar-menu-tool-top-fill-width {
  min-width: calc(
    100dvw - #{$sidebar-width__expanded} - #{$sider-menu__collapsed} - (#{$panel-edge-margin} * 2)
  ) !important;
  width: calc(
    100dvw - #{$sidebar-width__expanded} - #{$sider-menu__collapsed} - (#{$panel-edge-margin} * 2)
  ) !important;
}
.navbar-menu-tool-content-top {
  max-height: 120px !important;
}
.navbar-menu-tool-left {
  top: 125px !important;
  left: $panel-edge-margin !important;
  min-width: calc(25vw - (#{$panel-edge-margin} * 2)) !important;
  width: calc(25vw - (#{$panel-edge-margin} * 2)) !important;
  height: 59vh !important;
  display: flex;
  flex-direction: column;
}
.navbar-menu-tool-content-left {
  flex-grow: 1;
  overflow-y: auto;
}
.navbar-menu-tool-sticky-buttons {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
